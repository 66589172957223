<template>
  <div >
    <el-row :gutter="10">

      <el-col
        v-for="n in scaleConfig.varsAmount"
        :key="n"
        class="library-view-number-multiple-col"
        :span="scaleConfig.viewSpan"
      >
        <div><b>Цель {{n}}</b>: {{ scale.targets['col'+n] }}{{ (scaleConfig.colsConfig && scaleConfig.colsConfig['col'+n] && scaleConfig.colsConfig['col'+n].percentFrom) ? '%' : '' }}</div>
      </el-col>

    </el-row>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "library-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        targets: {},
        pays: {},
      },
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
</style>